.customWrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 220px !important;
  }
  
  .customEditor {
    height: 200px !important;
    border: 1px solid #f1f1f1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
  }